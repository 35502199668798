import type { HexColor } from '@getaccept/fried-tofu';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { DateTime } from 'luxon';

export function getGlobalHandle() {
  return window as any;
}

export const testMobileNumber = (number: string): boolean =>
  parsePhoneNumberFromString(number)?.isValid() || customPhoneNumberValidaiton(number) || false;

//+61483 is a AU number that is not supported by libphonenumber-js
const AU_48: { key: string; pattern: string } = { key: 'AU', pattern: '+61483' };
const AU_49: { key: string; pattern: string } = { key: 'AU', pattern: '+61493' };
const customNumbers = [AU_48, AU_49];

export const customPhoneNumberValidaiton = (number: string): boolean =>
  customNumbers.some(customNumber => number.includes(customNumber.pattern));

export const customPhoneNumberCountryCode = (number: string): string => {
  const customNumber = customNumbers.find(customNumber => number.includes(customNumber.pattern));
  return customNumber?.key || '';
};

export const initialHelper = (fullName: string): string => {
  const names: string[] = fullName?.split(' ') || [];
  if (names.length === 0) {
    return '';
  }
  let initials = names[0].substring(0, 1);
  if (names.length > 1) {
    initials = initials + names[names.length - 1].substring(0, 1);
  } else {
    if (names[0].length > 1) {
      initials = names[0].substring(0, 2);
    }
  }
  return initials.toUpperCase();
};

export const validateEmail = (email: string): boolean => {
  const regex = /^(?!.*\.\.)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/;
  return !!email.match(regex);
};

export function sanitizeEmail(email: string): string {
  return email.toLowerCase().trim();
}

export function capitalizeFirstLetter(string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function throttle(func: (arg?) => any, rate: number) {
  let enabled = true;

  return function (arg?: any) {
    if (enabled) {
      func(arg);
      enabled = false;
      setTimeout(() => {
        enabled = true;
      }, rate);
    }
  };
}

export const kebabToCamelCase = (str): string =>
  str.toLowerCase().replace(/-./g, x => x[1].toUpperCase());

export const pascalToSnakeCase = (str): string =>
  str.replace(/(?:^|\.?)([A-Z])/g, (_, letter) => `_${letter.toLowerCase()}`).replace(/^_/, '');

export const convertDateStringToISOString = (dateString?: string) => {
  if (!dateString) return '';
  const date = DateTime.fromSQL(dateString);
  return date.toISO();
};

export const extractNumberFromString = (str: string): number => {
  const num = str.replace(/[^0-9]/g, '');
  return parseInt(num) || 0;
};

export const capitalize = (text: string) => (text ? text[0].toUpperCase() + text.slice(1) : '');

export const truncateText = (text: string, maxLength = 60) => {
  if (text.length > maxLength) {
    return `${text.slice(0, maxLength)}...`;
  }
  return text;
};

export const hexToRgb = (hex: string): number[] => {
  const bigint = parseInt(hex.replace('#', ''), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return [r, g, b];
};

export const rgbToHex = (rgb: number[]) => {
  const hex = rgb
    .map(x => {
      const hex = x.toString(16);
      return hex.length === 1 ? `0${hex}` : hex;
    })
    .join('');
  return `#${hex}`;
};

export const hexToHsl = (hex: string) => {
  if (!hex) {
    return {
      h: 0,
      s: 0,
      l: 0,
    };
  }
  hex = hex.replace('#', '');

  if (hex.length === 3) {
    hex = hex
      .split('')
      .map(char => char + char)
      .join('');
  }

  const r = parseInt(hex.substring(0, 2), 16) / 255;
  const g = parseInt(hex.substring(2, 4), 16) / 255;
  const b = parseInt(hex.substring(4, 6), 16) / 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);

  let h = 0;
  let s = 0;
  const l = (max + min) / 2;

  if (max !== min) {
    s = l > 0.5 ? (max - min) / (2 - max - min) : (max - min) / (max + min);

    switch (max) {
      case r:
        h = (g - b) / (max - min) + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / (max - min) + 2;
        break;
      case b:
        h = (r - g) / (max - min) + 4;
        break;
    }
    h /= 6;
  }

  return {
    h: Math.round(h * 360),
    s: Math.round(s * 100),
    l: Math.round(l * 100),
  };
};

const colorChannelToHex = (channelValue: number, adjustment: number): string => {
  const hex = Math.round((channelValue + adjustment) * 255).toString(16);
  return hex.padStart(2, '0');
};

export const hslToHex = (h: number, s: number, l: number): HexColor => {
  s /= 100;
  l /= 100;

  const c = (1 - Math.abs(2 * l - 1)) * s;
  const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
  const m = l - c / 2;

  let r = 0;
  let g = 0;
  let b = 0;

  if (0 <= h && h < 60) {
    r = c;
    g = x;
    b = 0;
  } else if (60 <= h && h < 120) {
    r = x;
    g = c;
    b = 0;
  } else if (120 <= h && h < 180) {
    r = 0;
    g = c;
    b = x;
  } else if (180 <= h && h < 240) {
    r = 0;
    g = x;
    b = c;
  } else if (240 <= h && h < 300) {
    r = x;
    g = 0;
    b = c;
  } else if (300 <= h && h < 360) {
    r = c;
    g = 0;
    b = x;
  }

  return `#${colorChannelToHex(r, m)}${colorChannelToHex(g, m)}${colorChannelToHex(b, m)}`.toUpperCase() as HexColor;
};

export const hasValidNumberProperty = <T>(obj: T, propName: keyof T): boolean => {
  if (!obj) {
    return false;
  }
  return typeof obj[propName] === 'number' && obj[propName] !== null;
};
